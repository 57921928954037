import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import { Plugin } from "vue-responsive-video-background-player";
import VueGtag from "vue-gtag";

createApp(App)
  .use(Plugin)
  .use(VueGtag, {
    config: {
      id: "1:580244660331:web:4d8f2c22bff9795df6d12e",
    },
  })
  .use(router)
  .use(Buefy)
  .mount("#app");
